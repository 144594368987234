export const COLORS = {
  FONT: "#fff",
  BACKGROUND: "#333",

  TOPBAR: {
    FONT: "#fff",
    BACKGROUND: "#243",
    BORDER: "#243",
  },

  SIDEBAR: {
    FONT: "#fff",
    BACKGROUND: "#222",
    BACKGROUND_HOVER: "#444",
    BACKGROUND_ACTIVE: "#444",
    BORDER: "darkgrey",
  },

  GROUP_BAR: {
    FONT: "#fff",
    BACKGROUND: "#222",
    BACKGROUND_HOVER: "#444",
    BACKGROUND_ACTIVE: "#444",
    BORDER: "grey",
  },
};
