// Labels de las columnas en los grupos
export const GROUP_COLUMNS = [
  "Equipo",
  "JJ",
  "JG",
  "JE",
  "JP",
  "GF",
  "GC",
  "DG",
  "PTS",
];

// Mínimo número de equipos para mostrar una columna extra con la posición en la tabla
export const MIN_NUM_TEAMS_FOR_NUMERATION = 6;

// Máximo número de equipos para aplicar un sorteo aleatorio (por arriba de eso se mantiene el orden inicial)
export const MAX_NUM_TEAMS_FOR_DRAW = 4;
