import { styled } from "styled-components";
import { COLORS as C } from "styles/colors";

export const Layout = styled.div`
  color: ${C.FONT};
  background-color: ${C.BACKGROUND};
`;

export const Topbar = styled.div`
  display: flex;
  align-items: center;
  color: ${C.TOPBAR.FONT};
  background-color: ${C.TOPBAR.BACKGROUND};
  white-space: nowrap;
  height: 4rem;

  img {
    height: 2.5rem;
    margin-left: 1rem;
  }

  header {
    border-bottom: thin solid ${C.TOPBAR.BORDER};
    line-height: calc(2rem - 1px);
    padding: 1rem;
  }

  p {
    font-size: 0.75rem;
    margin-left: auto;
    margin-right: 1rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

type SidebarProps = {
  $isOpenSidebar: boolean;
};

export const Sidebar = styled.div<SidebarProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${C.SIDEBAR.FONT};
  background-color: ${C.SIDEBAR.BACKGROUND};
  height: calc(100vh - 4rem);
  overflow-y: auto;
  border-right: thin solid ${C.SIDEBAR.BORDER};
  width: ${({ $isOpenSidebar }) => !$isOpenSidebar && 0};
  border: ${({ $isOpenSidebar }) => !$isOpenSidebar && "none"};
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  gap: 0.5rem;
  flex: 1;

  div {
    white-space: nowrap;
    overflow-x: hidden;
    padding: 1rem;
    border-radius: 0.75rem;
  }

  div:hover {
    background-color: ${C.GROUP_BAR.BACKGROUND_HOVER};
  }

  a {
    &.active {
      div {
        background-color: ${C.GROUP_BAR.BACKGROUND_ACTIVE};
      }
    }
  }
`;

type MinimizeButtonProps = {
  $isOpenSidebar: boolean;
};

export const MinimizeButton = styled.div<MinimizeButtonProps>`
  position: absolute;
  bottom: 0;
  background-color: ${C.GROUP_BAR.BACKGROUND_ACTIVE};
  border: thin solid ${C.SIDEBAR.BORDER};
  border-radius: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: pointer;

  transition: transform 0.3s ease;
  transform: rotateY(
    ${({ $isOpenSidebar }) => ($isOpenSidebar ? "0deg" : "180deg")}
  );
  left: ${({ $isOpenSidebar }) => ($isOpenSidebar ? "1rem" : "-0.75rem")};
  border: ${({ $isOpenSidebar }) => $isOpenSidebar && "none"};
`;

export const Content = styled.div`
  flex: 1;
  height: calc(100vh - 4rem);
  overflow-y: auto;
`;
