import { useEffect, useState } from "react";
import * as S from "./styles";
import { useData } from "DataContext";
import { Badge } from "components/Badge";
import { TeamType } from "types";
import { GROUP_COLUMNS } from "parameters";

type CellType = {
  value: string | number;
  index: number;
  tiebreak: string;
};

export const Cell: React.FC<CellType> = ({ value, index, tiebreak }) => {
  const [animate, setAnimate] = useState(false);
  const { data } = useData();

  const TEAMS: TeamType = data.TEAMS;

  useEffect(() => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 300);
  }, [value]);

  const cellValue = () => {
    // Badge y Nombre de equipo
    if (index === 0) {
      const { label, badge = "" } = TEAMS[value];
      return (
        <S.Team>
          <Badge url={badge} />
          <span>{label}</span>
        </S.Team>
      );
    }

    // DG
    if (index === 7) {
      return `${Number(value) >= 0 ? "+" : ""}${value}`;
    }

    return value;
  };

  return (
    <>
      <S.Cell className={animate ? "animate" : ""}>{cellValue()}</S.Cell>
      {index === GROUP_COLUMNS.length - 1 && (
        <S.TiebreakContainer>
          <S.TiebreakContent>{tiebreak}</S.TiebreakContent>
        </S.TiebreakContainer>
      )}
    </>
  );
};
