import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { App } from "App";
import { Loading } from "components/Loading";
import { fetchDataFromDDBB } from "./firebase";
import {
  CompetitionDefineType,
  CompetitionType,
  GameType,
  GroupType,
} from "./types";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AppLoader: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchDataFromDDBB();

        const COMPETITIONS_DEFINE: CompetitionDefineType[] =
          fetchedData.COMPETITIONS_DEFINE;
        const DATA_GAMES: GameType[] = fetchedData.GAMES;

        const DATA_GROUPS: GroupType[] = [];
        COMPETITIONS_DEFINE.filter((e) => e.active).forEach((c) =>
          c.groups.forEach((g) =>
            DATA_GROUPS.push({
              competitionId: c.id,
              id: g.id,
              label: g.label,
              teams: g.teams,
              qualification: g.qualification,
              games: DATA_GAMES.filter(
                (game) => game.competitionId === c.id && game.group === g.id
              ),
            })
          )
        );

        const COMPETITIONS_DATA: CompetitionType[] = COMPETITIONS_DEFINE.filter(
          (e) => e.active
        ).map((competition) => ({
          id: competition.id,
          name: competition.name,
          groups: DATA_GROUPS.filter(
            (group) => group.competitionId === competition.id
          ),
          qualification: competition.qualification,
          rules: competition.rules,
        }));

        setData({ ...fetchedData, COMPETITIONS_DATA });
      } catch (error) {
        console.error("Error al obtener datos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return isLoading ? <Loading /> : <App initialData={data} />;
};

root.render(
  <React.StrictMode>
    <AppLoader />
  </React.StrictMode>
);
